import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import './footer.css'

const Footer = () => (
  <footer
    // style={{
    //   marginTop: `2rem`,
    //   backgroundColor: `#1f1f1f`,
    //   position:`absolute`,
    //   left:0,
    //   bottom:0,
    //   right:0,
    // }}
  >
    <div
      style={{
        // margin: `0 auto`,
        // maxWidth: 1440,
        color: `#666`,
        padding: `1rem 1.0875rem`,
      }}
    >
      © {new Date().getFullYear()}, CraftTech Labs
    </div>
  </footer>
)

export default Footer
